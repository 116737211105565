import { css } from '@emotion/core';
import { CSSProperties } from 'react';

import { buttons } from './button.styles';
import { fields } from './field.styles';
import { inputs } from './input.styles';
import { color } from './mixins.styles';
import { mqFrom, mqTo } from './responsive.styles';
import { typescale } from './typescale.styles';
import { colors, fontFamily, fontWeights, globalRadial } from './vars.styles';

const layout = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column-reverse;
  padding: 18px 0;

  ${mqFrom.Med} {
    flex-direction: row;
    padding-top: 52px;
  }
`;

const layoutFlexItem = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${mqFrom.Med} {
    width: 45%;
  }
`;

const leftSection = css`
  max-width: 510px;
`;

const colorsWrapper = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 24px 20px;
  border-top: 1px solid ${color(colors.dark, 0.1)};
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
  width: 100%;

  ${mqFrom.Med} {
    padding: 24px 0;
    margin-top: 20px;
  }
`;

const colorAlternativeWrapper = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  & > span {
    margin-right: 54px;
  }
`;

const colorsPallete = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const colorAlternative = css`
  margin-right: 20px;
  height: 36px;
  position: relative;
`;

const colorCircle = css`
  padding: 4px;
  border-radius: 1000px;
`;

const colorCircleActive = css`
  border: 1px solid black;
`;

const tooltip = css`
  display: none;
  position: absolute;
  width: max-content;
  top: 40px;
  left: 0;
  height: 28px;
  padding: 4px 12px;
  ${typescale.small}
  background: #E7E7E7;
  border-radius: ${globalRadial}px;
  z-index: 2;
`;

const tooltipActive = css`
  display: inline-block;
`;

const titleFlex = css`
  flex-direction: column-reverse;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  ${mqFrom.Med} {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
`;

const brand = css`
  justify-self: flex-end;
  flex: 1;

  & > div {
    float: right;
  }
`;

const guarantee = css`
  ${typescale.tiny}
  background: #F8F8F8;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  padding: 0 20px;

  & > span {
    margin-left: 10px;
  }

  ${mqFrom.Med} {
    padding: 8px 12px;
  }
`;

const title = css`
  ${typescale.large}
  font-weight: ${fontWeights.weightRegular};
  color: ${colors.dark};
  margin-bottom: 4px;
`;

const skuNumber = css`
  ${typescale.small}
  color: ${colors.text};
  opacity: 0.5;
  margin-bottom: 20px;
  padding: 0 20px;

  ${mqFrom.Med} {
    padding: 0;
  }
`;

const ctaWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
  margin-bottom: 48px;
  padding: 20px 0px;

  ${mqTo.Med} {
    padding: 36px 20px;
  }

  ${mqFrom.Xlrg} {
    flex-direction: row;
    align-items: center;
    padding: 36px 0;
  }
`;

const quantityInput = css`
  ${inputs.input.small}
  margin-bottom: 0px;

  width: 70px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const inputsFlex = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;

  ${mqFrom.Xlrg} {
    margin-bottom: 0px;
    margin-right: 16px;
    height: 100px;
    align-items: flex-start;
  }
`;

const desktopWidth = css`
  width: 100%;
  height: 150px;
  ${mqFrom.Xlrg} {
    width: 320px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const addToCartWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;

  ${mqFrom.Xlrg} {
    height: 100px;
  }
`;

const ctaBtn = css`
  ${buttons.primary.small}
  width: 100%;
  display: inline-block;
`;

const ctaPrice = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  ${mqFrom.Med} {
    margin-right: 20px;
    width: 190px;
  }
`;

const suggestedPriceLabel = css`
  ${typescale.tiny}
  color: ${color(colors.textSecondary, 0.8)};
`;

const suggestedPrice = css`
  ${typescale.small}
  color: ${color(colors.textSecondary, 0.4)};
  margin-bottom: 4px;

  & > span {
    ${typescale.xlarge}
    color: ${colors.dark};
  }
`;

const suggestedPriceInEuros = css`
  ${typescale.small}
  color: ${color(colors.textSecondary, 0.4)};
  margin-bottom: 4px;

  & > span {
    ${typescale.medium}
    color: ${colors.dark};
  }
`;

const pricePerMetric = css`
  ${typescale.small}
  color: ${colors.textSecondary};
`;

const subTitle = css`
  ${typescale.medium}
  color: ${colors.dark};
  margin-bottom: 24px;
  font-weight: ${fontWeights.weightRegular};
`;

const characteristicsTable = css`
  width: 100%;
  ${typescale.small}
  color: ${colors.textSecondary};
  background: ${colors.backgroundGray};
  padding: 24px 32px;
  margin-bottom: 36px;

  & > p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${color(colors.dark, 0.1)};
    margin-bottom: 12px;
    padding-bottom: 12px;

    & > span:last-of-type {
      font-weight: ${fontWeights.weightBold};
    }
  }

  & > p:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const calculators = css`
  margin-bottom: 48px;
  width: 100%;
`;

const calculatorBtn = css`
  color: ${colors.primary};
  padding: 14px 30px;
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
  display: flex;
  align-items: center;
  width: 100%;

  & > button {
    ${buttons.container}
    color: ${colors.primary};
    padding-left: 16px;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: auto;
  }

  ${mqFrom.Med} {
    padding: 14px 0;
  }
`;

const linkButton = css`
  color: ${colors.primary};
  padding: 14px 30px;
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
  display: flex;
  align-items: center;
  width: 100%;

  & > a {
    ${buttons.container}
    color: ${colors.primary};
    padding-left: 16px;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: auto;
  }

  ${mqFrom.Med} {
    padding: 14px 0;
  }
`;

const productDetails = css`
  margin-bottom: 50px;
  width: 100%;
`;

const howToMaterials = css`
  ${typescale.small}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${colors.textSecondary};
  padding-bottom: 14px;
  border-bottom: 1px solid ${color(colors.dark, 0.1)};

  & > a {
    margin-left: auto;
  }
`;

const desktopImageWrapper = css`
  display: none;

  ${mqFrom.Med} {
    display: flex;
    flex-direction: column;
  }
`;

const mobileImageWrapper = css`
  display: flex;

  ${mqFrom.Med} {
    display: none;
  }
`;

const horizontalListWrapper = css`
  width: 100%;
  margin: 80px 0;
  padding: 0 20px;

  ${mqFrom.Med} {
    padding: 0;
  }
`;

const horizontalList = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: fit-content;
`;

const horizontalListScroll = css`
  overflow-x: scroll;
  padding: 2px;
  padding-bottom: 32px;
  width: 100%;
`;

const horizontalListItem = css`
  width: 180px;
  height: 240px;
  margin-right: 40px;
  padding: 20px 10px;
  ${typescale.small}
  color: ${colors.textSecondary};
  text-align: center;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 0px 2px rgba(179, 0, 0, 1);
  }
`;

const quantityWrapper = css`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 0px;
  }

  ${mqFrom.Xlrg} {
    margin-top: -20px;
  }
`;

const errorMsg = css`
  ${fields.errorMsg};
  margin-top: 0;
`;

const defaultPhoto = css`
  width: 243px;
  height: 243px;
`;

const desktopDescription = css`
  display: none;

  ${mqFrom.Med} {
    display: contents;
  }
`;

const mobileDescription = css`
  display: block;
  padding: 0 20px;

  ${mqFrom.Med} {
    display: none;
  }
`;

const desktopTechnicalDetails = css`
  ${desktopDescription}
`;

const mobileTechnicalDetails = css`
  ${mobileDescription}
  padding: 0;
`;

const desktopCalc = css`
  display: none;

  ${mqFrom.Med} {
    display: block;
  }
`;

const colorOver = css`
  &:hover {
    cursor: pointer;
  }
`;

const center = css`
  justify-content: center;
  align-items: center;
`;

const photo = css`
  width: 105px;
  margin: 0 -10px;
  image-rendering: -webkit-optimize-contrast;
  float: right;
`;

const remarkWrapper = css`
  padding-top: 20px;
`;

const remark = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  ${typescale.small};

  color: ${colors.dark};
`;

const colorImage = css`
  width: 28px;
  height: 28px;
`;

const colorInfoWrapper = css`
  display: flex;
  padding-top: 22px;
  flex-direction: column;
`;

const colorInfo = css`
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};

  ${typescale.small};

  color: ${colors.textSecondary};

  padding-left: 12px;
`;

const colorInfoBox = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
`;

const iframe = css`
  width: 100%;
  height: 230px;
  margin-right: 24px;
`;

export const product = {
  layout,
  layoutFlexItem,
  leftSection,
  colorsWrapper,
  colorInfoBox,
  colorsPallete,
  colorAlternative,
  colorCircle,
  colorCircleActive,
  tooltip,
  tooltipActive,
  titleFlex,
  brand,
  guarantee,
  title,
  skuNumber,
  linkButton,
  ctaWrapper,
  quantityInput,
  inputsFlex,
  ctaBtn,
  ctaPrice,
  suggestedPriceLabel,
  suggestedPrice,
  suggestedPriceInEuros,
  pricePerMetric,
  subTitle,
  characteristicsTable,
  calculators,
  calculatorBtn,
  productDetails,
  howToMaterials,
  desktopImageWrapper,
  mobileImageWrapper,
  horizontalListWrapper,
  horizontalListScroll,
  horizontalList,
  horizontalListItem,
  quantityWrapper,
  errorMsg,
  defaultPhoto,
  desktopDescription,
  mobileDescription,
  desktopTechnicalDetails,
  mobileTechnicalDetails,
  desktopCalc,
  colorOver,
  center,
  photo,
  desktopWidth,
  addToCartWrapper,
  remark,
  remarkWrapper,
  colorImage,
  colorAlternativeWrapper,
  colorInfoWrapper,
  colorInfo,
  iframe,
};

export const deliveryStyles = {
  container: (styles: CSSProperties) => ({
    ...styles,
    height: '40px',
    width: '180px',
    marginBottom: '20px',
  }),
  indicatorSeparator: (styles: CSSProperties) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    paddingRight: 16,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    height: '40px',
    minHeight: '40px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.6)',
      cursor: 'pointer',
    },
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    height: '40px',
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    height: '40px',
  }),
};
